@media (max-width: 768px) {
  .main-footer {
    display: flex;
    flex-direction: column;

    .float-start {
      order: 2;
      p {
        text-align: center;
        font-size: 10px;
      }
    }
    .float-end {
      order: 1;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
